@use "src/App.scss" as app;

.layout{
  min-height: 100vh;
  display: grid;
  grid-template-columns: app.$drawer-width auto;
  background-color: app.$background-color;
}

.outlet{
  height: 100%;
  display: grid;
  grid-template-rows: app.$navbar-height app.$filter-height + 10px auto;
  grid-gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
}