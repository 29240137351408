@use "src/App.scss" as app;

.filterWrapper{
  display: flex;
  flex-direction: row;
  height: app.$filter-height;
  padding: 5px;
  border-radius: 0.5em;
  border: 1px solid #C1C2DDFF;
  background-color: app.$filter-background-color;
  z-index: 450;
}

.filterWrapper > div {
  margin: 0 10px 0 10px;
  flex-basis: 10%;
}

.applyButton{
  margin-left: 10px !important;
  background-color: app.$pink !important;
  border-radius: 30px !important;
  width: 120px;
}

.applyButton:disabled{
  background-color: #aaaaaa !important;
}

.restButton{
    color: app.$pink !important;
}