.dataGrid {
  height: 90vh;
}

.dataGrid.testBoolean[data-value="true"] {
  color: green;
}

.dataGrid.testBoolean[data-value="true"] {
  color: red;
}

.MuiDataGrid-booleanCell[data-value="true"] {
  color: green;
}