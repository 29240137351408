$drawer-width: 200px;
$navbar-height: 50px;
$filter-height: 50px;

// Primary Colors
$bright-pink: #fd00fe;
$pink: #fd0098;
$red: #fd3333;
$background-color: #f1f3fc;
$filter-background-color: #fbfcfe;

// Secondary Colors
$blue: #00005c;
$blue-selected: #21256e;
$purple: #9d005c;
$orange: #ec5426;
$skin: #fd9866;
$pale-blue: #7276c0;

// Gradient Colors
$main-gradient: linear-gradient(90deg, rgba(253,51,51,1) 0%, rgba(253,31,130,1) 53%, rgba(253,0,252,1) 100%);
$secondary-gradient: linear-gradient(90deg, rgb(253, 1, 245) 0%, rgb(253, 1, 159) 53%, rgb(253, 145, 104) 100%);

// chart
$chart-purple: #7276c0;
$chart-blue: #5aaffe;
$chart-gray: #808080;

// pie chart
$chart-pink: #fb1998;
$chart-gray: #e6e7f1;
$chart-bright-blue: #5aaffe;
$chart-blue: #51548c;

:export{
  bright-ping: $bright-pink;
  pink: $pink;
  red: $red;
  background-color: $background-color;
  filter-background-color: $filter-background-color;
  blue: $blue;
  blue-selected: $blue-selected;
  purple: $purple;
  orange: $orange;
  skin: $skin;
  paleBlue: $pale-blue;
  chartPink: $chart-pink;
  chartGray: $chart-gray;
  chartBrightBlue: $chart-bright-blue;
  chartBlue: $chart-blue;
}