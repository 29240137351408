@use "src/App.scss" as app;

.sidebarIcon > .MuiIconButton-root > .span{
  color: #b5b6d5;
}

.sidebarIcon > .MuiIconButton-root{
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1rem;
}

.sidebarIcon > .MuiIconButton-root > img{
  width: 15%;
  padding: 20px;
}


.sidebarIcon {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 10px 0 10px;
  background-color: app.$blue;
}

.sidebarIcon span{
  color: #cccccc;
}

.sidebarIconSelected {
    background-color: app.$blue-selected;
}

@media screen and (max-height: 820px) {
  .sidebarIcon > .MuiIconButton-root > img{
    width: 10%;
    padding: 10px;
  }
}