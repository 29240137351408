@use "src/App.scss" as app;

.MuiToolbar-root{
  padding: 0 !important;
  justify-content: space-between;
}

.MuiToolbar-root > h1{
  font-size: 20px;
  color: app.$blue;
}