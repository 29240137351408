.layout{
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.header{
  display: flex;
}

.videoDownload{
  margin-left: auto !important;
  color: #fb279e !important;
  .MuiButton-text{
      color: #fb279e !important;
    }
  img{
    margin-right: 10px;
    padding-bottom: 3px;
    width: 25px;
  }
}

.MuiDataGrid-columnHeaders {
  display: none !important;
}

.dateCell{
  color: #808080;
  font-family: "Helvetica Neue", Helvetica, serif;
}