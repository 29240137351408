body {
    margin: 0 !important;
    min-height: 100vh !important;
}

html {
    min-height: 100vh !important;
    min-width: 880px;
    font-family: 'Lato', sans-serif;
}

#root{
    min-height: 100vh !important;
}