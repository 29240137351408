@use "src/App.scss" as app;

.MuiDrawer-root{
  height: 100%;
}

.MuiDrawerPaper{
  background: app.$blue !important;
  width: app.$drawer-width;
  height: 100%;
}

.Img{
  width: 80px;
}

.RVIcon{
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebarWrapper{
  display: grid;
  grid-template-rows: 100px auto 50px;
  height: 100%;
}

.MuiGrid-item{
  justify-content: center;
  text-align: center;
}

.iconsWrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.row{
  flex-direction: column;
  display: flex;
}

@media screen and (max-height: 820px) {
  .Img{
    width: 50px;
  }

  .sidebarWrapper{
    grid-template-rows: 50px auto 50px;
  }

  .sidebarIcon .MuiIconButton-root .Img{
    width: 10%;
    padding: 10px;
  }
}

.version{
  color: white;
  display: flex;
  justify-content: center;
}