.popoverRoot {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateRangeWrapper {

  display: flex;
  flex-direction: column;
}
.timeContainer{
   display: flex;
   justify-content: space-between;
  margin: 10px;


}